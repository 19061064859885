<template>
  <div class="login">
    <el-card class="login_card">
      <h1 class="login_title">后台管理</h1>
      <el-form :model="loginInfo" ref="loginInfo" label-width="100px" class="login_form" :rules="rules">
        <el-form-item prop="name" label="账号">
          <el-input v-model="loginInfo.name" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input v-model="loginInfo.password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('loginInfo')">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import md5 from 'md5'
export default {
  data() {
    return {
      loginInfo: {
        name: '',
        password: '',
      },
      rules: {
        name: [{ required: true, message: '请输入账号', trigger: ['blur', 'change'] }],
        password: [{ required: true, message: '请输入密码', trigger: ['blur', 'change'] }]
      }
    }
  },
  mounted() {
    this.$store.commit("logout")
  },
  methods: {
    // 提交
    submitForm(formName) {
      this.useLogin()
    },
    // 登录
    useLogin() {
      this.$request.post("/user/adminLogin", {
        name: this.loginInfo.name,
        password: md5(this.loginInfo.password), // 加密
      }).then((res) => {
        //   登录成功
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.$store.commit("login", res.data)
        this.$message.success(res.message)
        this.$router.push('/')
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  background-color: rgb(10, 4, 31);
  width: 100vw;
  height: 100vh;

  .check_box {
    margin-bottom: 18px;

    ::v-deep .el-form-item__content {
      line-height: 0;
    }
  }

  .login_title {
    text-align: center;
    padding-bottom: 36px;
  }

  .login_card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    padding: 18px;
  }
}
</style>
